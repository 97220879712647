import { render, staticRenderFns } from "./errorHistory.vue?vue&type=template&id=36871f95&scoped=true&"
import script from "./errorHistory.vue?vue&type=script&lang=js&"
export * from "./errorHistory.vue?vue&type=script&lang=js&"
import style0 from "./errorHistory.vue?vue&type=style&index=0&id=36871f95&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36871f95",
  null
  
)

export default component.exports