<template>
  <!-- 错误记录 -->
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <div class="datePicker">
      操作日期：
      <a-range-picker
        style="width: 300px"
        v-model="year"
        @change="timeChange"
      />
      <a-radio-group
        style="margin-left: 170px"
        v-model="status"
        @change="searchOk"
      >
        状态：
        <a-radio-button value="0"> 待处理</a-radio-button>
        <a-radio-button value="1"> 已处理 </a-radio-button>
      </a-radio-group>
      <a-button type="primary" style="margin-left: 90px" @click="search"
        >查询</a-button
      >
      <a-button style="margin-left: 30px" @click="clear">重置</a-button>
      <a-button style="position: absolute; right: 20px" @click="expotExcel()">
        <a-icon type="file-excel" style="color: green" /> 批量导出</a-button
      >
    </div>

    <div style="padding: 20px; background-color: #fff">
      <a-table
        :columns="columns"
        style="min-height: 500px; background-color: #fff"
        :data-source="tableList"
        :rowKey="(record) => record.id"
        :scroll="{ x: 1500 }"
        :pagination="false"
      >
        <!-- 悬浮框 -->
        <span slot="tooltip" slot-scope="text, item">
          <!-- 悬浮框 -->
          <a-tooltip placement="top">
            <template slot="title">
              {{ item.content }}
            </template>
            <!-- 内容 -->
            <div class="multi-ellipsis">
              <span class="span">{{ item.content }}</span>
            </div>
          </a-tooltip>
        </span>
        <span slot="is_ok" slot-scope="text, record, index">
          <!-- 待处理 -->
          <a-tag color="green" v-if="record.status == 0">待处理</a-tag>
          <a-tag color="blue" v-if="record.status == 1">已处理</a-tag>
        </span>
        <span slot="is_operation" slot-scope="text, record, index">
          <a v-if="record.status == 0" @click="Solution(record.id)">解决方案</a>
          <a v-if="record.status == 1" @click="look(record)">查看</a>
        </span>
      </a-table>
      <!-- table框的分页 -->
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          v-model="pagination.current"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>

    <a-modal
      title="解决方案"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :width="500"
    >
      <textarea
        placeholder="请输入"
        style="
          width: 100%;
          min-height: 180px;
          border: 1px solid #dad3d3;
          padding: 10px;
          outline: none;
        "
        v-model="result"
      >
      </textarea>
    </a-modal>

    <a-modal
      title="解决方案"
      :visible="lookVisible"
      @ok="lookOk"
      @cancel="lookCancel"
      :width="500"
    >
      <textarea
        placeholder="请输入"
        disabled
        style="
          width: 100%;
          min-height: 180px;
          border: 1px solid #dad3d3;
          padding: 10px;
          outline: none;
        "
        v-model="lookResult"
      >
      </textarea>
    </a-modal>
  </div>
</template>

<script>
import {
  getErrorRecord,
  editErrorRecord,
  getdrugErrorExport,
} from "@/service/DrugQueryManagement";
export default {
  data() {
    return {
      visible: false,
      result: "",
      year: [],
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      startTime: "",
      endTime: "",
      status: "0",
      result: "",
      tableList: [],
      lookResult: "",
      lookVisible: false,
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          align: "center",
          width: "8%",
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          key: "hospitalName",
          align: "center",
          width: "20%",
        },
        {
          title: "科室",
          dataIndex: "hospitalOffices",
          key: "hospitalOffices",
          align: "center",
          width: "10%",
        },
        {
          title: "职称",
          dataIndex: "jobTitle",
          key: "jobTitle",
          align: "center",
          width: "10%",
        },
        {
          title: "纠错药品名称",
          align: "center",
          dataIndex: "drugName",
          key: "drugName",
          width: "17%",
        },
        {
          title: "纠错内容描述",
          width: "23%",
          align: "center",
          dataIndex: "content",
          key: "content",
          scopedSlots: { customRender: "tooltip" },
        },
        {
          title: "操作时间",
          width: "15%",
          align: "center",
          dataIndex: "createdTime",
          key: "createdTime",
        },
        {
          title: "状态",
          width: "10%",
          align: "center",
          scopedSlots: { customRender: "is_ok" },
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: 100,
          scopedSlots: { customRender: "is_operation" },
        },
      ],
      id: "",
    };
  },

  created() {
    this.load();
  },
  methods: {
    async load() {
      const res = await getErrorRecord(
        this.pagination.pageNum,
        this.pagination.pageSize,
        this.startTime,
        this.endTime,
        this.status
      );
      this.tableList = res.data;
      this.pagination.total = res.count;
    },
    timeChange(date, dateString) {
      (this.startTime = dateString[0]), (this.endTime = dateString[1]);
      this.load();
    },
    search() {
      this.pagination.pageNum = 1;
      this.load();
    },
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.load();
    },
    searchOk(e) {
      this.status = e.target.value;
      // 重置页码
      this.pagination.pageNum = 1;
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.load();
    },
    clear() {
      (this.year = []),
        (this.startTime = ""),
        (this.endTime = ""),
        (this.status = ""),
        (this.pagination.pageNum = 1);
      this.pagination.pageSize = 10;
      this.load();
    },
    Solution(id) {
      this.visible = true;
      this.id = id;
    },
    async handleOk() {
      if (this.result !== "") {
        let data = {
          id: this.id,
          result: this.result,
        };
        const res = await editErrorRecord(data);
        if (res.code == 0) {
          this.$message.success("修改成功！");
          this.visible = false;
          this.result = "";
          this.load();
        }
      } else {
        this.$message.warn("请输入内容！");
      }
    },
    handleCancel() {
      this.visible = false;
      this.result = "";
    },
    look(record) {
      (this.lookVisible = true), (this.lookResult = record.result);
    },
    lookOk() {
      this.lookVisible = false;
    },
    lookCancel() {
      this.lookVisible = false;
    },
    // 导出
    async expotExcel() {
      const data = {
        startTime: this.startTime,
        endTime: this.endTime,
        ExelName: "药品查询纠错记录",
      };
      const response = await getdrugErrorExport(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}
.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 超出一行则以...形式隐藏
  -webkit-box-orient: vertical;
  cursor: pointer;
  // background-color: green;
}

::v-deep .ant-table-thead>tr>th {
		font-weight: bold;
		
	}
</style>
